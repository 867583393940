import woodCa from '../../contents/images/windows/bois/prices/BOIS-CHASSIS-ABATTANTS.svg';
import woodFb from '../../contents/images/windows/bois/prices/BOIS-FENETRES-BATTANTES.svg';
import woodPfb from '../../contents/images/windows/bois/prices/BOIS-PORTES-FENETRES-BATTANTES.svg';

import aluBc from '../../contents/images/windows/aluminium/prices/ALU-BAIES-COULISSANTES.svg';
import aluCa from '../../contents/images/windows/aluminium/prices/ALU-CHASSIS-ABATTANTS.svg';
import aluFc from '../../contents/images/windows/aluminium/prices/ALU-FENETRES-COULISSANTES.svg';
import aluOb from '../../contents/images/windows/aluminium/prices/ALU-FENETRES-OSCILLO-BATTANTES.svg';
import aluObs from '../../contents/images/windows/aluminium/prices/ALU-FENETRES-OSCILLO-BATTANTES-AVEC-VOLET-ROULANT-INTEGRE.svg';
import aluPfb from '../../contents/images/windows/aluminium/prices/ALU-PORTES-FENETRES-BATTANTES.svg';
import aluPfbs from '../../contents/images/windows/aluminium/prices/ALU-PORTES-FENETRES-BATTANTES-AVEC-VOLET-ROULANT.svg';

import pvcBc from '../../contents/images/windows/pvc/prices/PVC-BAIES-COULISSANTES-COTES-HORS-TOUT.svg';
import pvcCa from '../../contents/images/windows/pvc/prices/PVC-CHASSIS-ABATTANTS.svg';
import pvcOb from '../../contents/images/windows/pvc/prices/PVC-FENETRES-OSCILLO-BATTANTES.svg';
import pvcObs from '../../contents/images/windows/pvc/prices/PVC-FENETRES-OSCILLO-BATTANTES-AVEC-VOLET-ROULANT-INTEGRE.svg';
import pvcPfb from '../../contents/images/windows/pvc/prices/PVC-PORTES-FENETRES-BATTANTES.svg';
import pvcPfbs from '../../contents/images/windows/pvc/prices/PVC-PORTES-FENETRES-BATTANTES-AVEC-VOLET-ROULANT.svg';

const allPrices = {
    aluminium: {
        bc: aluBc,
        ca: aluCa,
        fc: aluFc,
        ob: aluOb,
        obs: aluObs,
        pfb: aluPfb,
        pfbs: aluPfbs,
    },
    bois: {
        ca: woodCa,
        fb: woodFb,
        pfb: woodPfb,
    },
    pvc: {
        bc: pvcBc,
        ca: pvcCa,
        ob: pvcOb,
        obs: pvcObs,
        pfb: pvcPfb,
        pfbs: pvcPfbs,
    },
};

export default allPrices;
