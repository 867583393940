import { graphql, Link } from 'gatsby';
import { Layer } from 'grommet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './WindowPricesTemplate.styl';

import SEO from '../components/SEO';
import MainLayout from '../layouts/MainLayout';
import LightBox from '../components/LightBox/index';
import Image from '../components/Image';
import allPrices from '../../constants/data/prices';

function WindowPricesTemplate({
    data,
    location,
    pageContext: { accessoriesLink, label, labelPlus, material, windowType },
}) {
    const [showDialog, setShowDialog] = useState(false);

    const { lightbox, mainPicture, thumbnails } = data;

    const open = () => {
        setShowDialog(true);
    };

    const close = () => {
        setShowDialog(false);
    };

    const lightboxImages = lightbox.edges.map(
        ({ node }) => node.childImageSharp,
    );

    const lightboxThumbnails = thumbnails.edges.map(
        ({ node }) => node.childImageSharp,
    );

    const getSVGComponent = () => {
        const svg = allPrices[material][windowType];
        const Component = svg;
        return <Component />;
    };

    return (
        <MainLayout
            headerOptions={{
                backButton: true,
            }}
        >
            <SEO
                title='Brico App'
                location={location}
                description='Brico App'
            />
            <div className='content WindowPricesTemplate container'>
                <div className='top'>
                    <div className='left'>
                        <div className='page-title'>
                            Dimensions et tarifs des{' '}
                            <span className='txt-red'>
                                {label.toLocaleLowerCase()}{' '}
                                {labelPlus !== null ? `avec ${labelPlus} ` : ''}
                            </span>
                        </div>

                        <div className='help'>
                            <i className='icon icon-infos' />
                            <span>
                                Demandez conseil à l’un de nos vendeurs pour
                                établir un devis précis
                            </span>
                        </div>
                        <Link to={accessoriesLink} className='link'>
                            <i className='icon icon-focus' />
                            <span>Tarifs des accessoires</span>
                        </Link>
                    </div>

                    <div className='right'>
                        <div className='img-bloc'>
                            <Image
                                alt='tarifs options v2'
                                {...mainPicture.childImageSharp}
                            />
                            <button
                                type='button'
                                className='pictures-link'
                                aria-label='open lightbox'
                                onClick={open}
                            >
                                <i className='icon icon-picture' />
                                <span>Voir les photos</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='table-bloc'>
                    <div className='img-container'>
                        {getSVGComponent()}

                        {/* <img src={allPrices[material][windowType]} alt='test' /> */}
                    </div>
                </div>
            </div>

            {showDialog && (
                <Layer onEsc={close} onClickOutside={close}>
                    <div className='lightbox-container'>
                        <button
                            className='close-button'
                            label='close'
                            type='button'
                            onClick={close}
                        >
                            <i className='icon icon-close' />
                        </button>
                        <LightBox
                            images={lightboxImages}
                            thumbnails={lightboxThumbnails}
                        />
                    </div>
                </Layer>
            )}
        </MainLayout>
    );
}

WindowPricesTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        // filter: PropTypes.string.isRequired,
        accessoriesLink: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        labelPlus: PropTypes.string,
        material: PropTypes.string.isRequired,
        windowType: PropTypes.string.isRequired,
    }).isRequired,
};

export default WindowPricesTemplate;

export const query = graphql`
    query ($mainPicture: String!, $lightBoxfilter: String!) {
        mainPicture: file(relativePath: { eq: $mainPicture }) {
            childImageSharp {
                gatsbyImageData(
                    width: 756
                    height: 342
                    transformOptions: { cropFocus: NORTH }
                    layout: FIXED
                )
            }
        }
        lightbox: allFile(
            filter: { relativeDirectory: { eq: $lightBoxfilter } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(width: 1283, layout: FIXED)
                    }
                }
            }
        }
        thumbnails: allFile(
            filter: { relativeDirectory: { eq: $lightBoxfilter } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            width: 380
                            transformOptions: { cropFocus: NORTH }
                            layout: FIXED
                        )
                    }
                }
            }
        }
    }
`;
