import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './LightBox.styl';
import clsx from 'clsx';
import Image from '../Image';

function LightBox({ images, thumbnails }) {
    console.log('🚀 ~ LightBox ~ images', images);
    const [selected, setSelected] = useState({ image: images[0], index: 0 });

    useEffect(() => {
        setSelected({ image: images[0], index: 0 });
    }, [images]);

    function selectImage(image, index) {
        if (index === selected.index) {
            return;
        }
        setSelected({ image: images[index], index });
    }

    return (
        <div className='lightbox'>
            {' '}
            <div className='selected-image'>
                <Image
                    {...selected.image}
                    alt='image lightbox'
                    objectFit='cover'
                    objectPosition='50% 50%'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            </div>
            <div className='thumbnails'>
                {thumbnails.map((item, index) => (
                    <div
                        key={`thumbnail-${index}`}
                        className={clsx('thumbnail-container', {
                            selected: selected.index === index,
                        })}
                        onClick={() => selectImage(item, index)}
                        tabIndex={index}
                        role='presentation'
                    >
                        <Image
                            {...item}
                            alt='thumbnail-lightbox'
                            objectFit='cover'
                            objectPosition='50% 50%'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

LightBox.propTypes = {
    images: PropTypes.array.isRequired,
    thumbnails: PropTypes.array.isRequired,
};

export default LightBox;
